import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {DatePicker, LocalizationProvider} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, {useMemo, useState} from 'react';
import {Column} from '../../api/Column';
import {BoxCentered, ButtonExportCsv} from '../common';
import {Table} from '../Table';
import {useTicketsExport} from './hooks/useTicketsExport';
import {transformTicketsForTable} from './utils/transformTicketForTable';

const columnsConfig: Column<Record<string, string | undefined>>[] = [
  {
    id: 'id',
    label: 'ID',
    export: true,
  },
  {
    id: 'created',
    label: 'Ticket Created',
    export: true,
  },
  {
    id: 'category',
    label: 'Category',
    export: true,
  },
  {
    id: 'assignee',
    label: 'Assignee',
    export: true,
  },
  {
    id: 'viewableBy',
    label: 'Viewable By',
    export: true,
  },
  {
    id: 'priority',
    label: 'Priority',
    export: true,
  },
  {
    id: 'labels',
    label: 'Labels',
    export: true,
  },
  {
    id: 'subject',
    label: 'Subject',
    export: true,
  },
  {
    id: 'description',
    label: 'Description',
    export: true,
  },
  {
    id: 'slaBreached',
    label: 'SLA Breached',
    export: true,
  },
  {
    id: 'lastInternalComment',
    label: 'Last Internal Comment',
    export: true,
  },
  {
    id: 'lastPublicComment',
    label: 'Last Public Comment',
    export: true,
  },
  {
    id: 'ticketHistory',
    label: 'Ticket History',
    export: true,
  },
];

export const TicketReports: React.FC = () => {
  const [date, setDate] = useState<Date | undefined>(new Date());
  const memoizedDate = useMemo(() => {
    return date ? {year: date.getFullYear(), month: date.getMonth()} : undefined;
  }, [date]);
  const {tickets, loading} = useTicketsExport(memoizedDate);

  console.log(tickets, loading);

  const ticketsData = transformTicketsForTable(tickets);

  return (
    <>
      <Box
        paddingTop={2}
        paddingBottom={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Breadcrumbs>
          <Box>Ticket Reports</Box>
        </Breadcrumbs>
        <Box sx={{display: 'flex', gap: '16px', alignItems: 'center'}}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Select date"
              views={['year', 'month']}
              value={date}
              onChange={date => setDate(date ?? undefined)}
              renderInput={params => (
                <TextField variant="outlined" size="small" {...params} sx={{width: '200px'}} />
              )}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarTodayIcon />
                  </InputAdornment>
                ),
              }}
            />
          </LocalizationProvider>
          <ButtonExportCsv
            filename={`all-tickets-${date?.getFullYear()}-${(date?.getMonth() ?? 0) + 1}`}
            rows={ticketsData}
            separator=";"
            disabled={loading}
            columnsConfig={columnsConfig}
          />
        </Box>
      </Box>
      <Paper>
        {loading ? (
          <BoxCentered height="50vh">
            <CircularProgress size={24} />
          </BoxCentered>
        ) : (
          <>
            {ticketsData.length === 0 && (
              <BoxCentered height="50vh">
                <Typography>There are no records</Typography>
              </BoxCentered>
            )}
            {ticketsData.length > 0 && (
              <Table
                stickyHeader
                scrollableBody
                customHeight="75vh"
                columns={columnsConfig.filter(
                  column => column.id !== 'ticketHistory' && column.id !== 'id'
                )}
                rows={ticketsData}
                getCellProps={(column, row) => ({
                  sx: [
                    column.id === 'ticketHistory' && {
                      'white-space': 'pre-line',
                    },
                  ],
                })}
              />
            )}
          </>
        )}
      </Paper>
    </>
  );
};
